import React from 'react';

import { removeNodes } from '../utils/getters';

import Header from './Header';
import Sections from './Sections';
import Faqs from './Faqs';
import HeadingOne from './HeadingOne';

function Page({ data }) {
  const { sections, faqs, title } = data?.content;
  const articles = removeNodes(data?.allArticles?.edges?.slice(0, 6));
  return (
    <>
      <Header articles={articles} />
      <div className="wrapper">
        <HeadingOne content={title}/>
        <Sections sections={sections} />
        <Faqs faqs={faqs} />
      </div>
    </>
  );
}

export default Page;
