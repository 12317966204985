import React, { useReducer } from 'react';

import { ContactFormContext } from '../contactForm';

import {
  SET_PREFERENCES,
} from '../../utils/variables';

export const ContactFormProvider = ({ children }) => {
  const initialState = {
    isError: false,
    isContactFormSent: false,
    isLoading: false,
    email: '',
    firstname: '',
    lastname: '',
    number: '',
    description: '',
    numberOfHomes: 0
  };

  const setPreferences = (state, payload) => {
    const { name, value } = payload;
    return {
      ...state,
      [name]: value
    };
  };

  function reducer(state, action) {
    const { type, payload } = action;
    switch (type) {
      case SET_PREFERENCES:
        return setPreferences(state, payload);
      default:
        return state;
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ContactFormContext.Provider value={{ state, dispatch }}>
      {children}
    </ContactFormContext.Provider>
  );
};
