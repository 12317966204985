import React from 'react';
import slugify from 'slugify';

import { INLINES, BLOCKS, MARKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

import Image from './Image';
import Button from './Button';

const getPlainTextFromHeader = (contentNode) => {
  return contentNode.reduce((acc, current) => {
    return acc + current.value;
  }, '');
};

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <b className="font-bold">{text}</b>,
    [MARKS.ITALIC]: (text) => <i className="font-italic">{text}</i>,
    [MARKS.UNDERLINE]: (text) => <u className="font-underline">{text}</u>,
    [MARKS.CODE]: (text) => (
      <code className="font-mono px-1 py-2 mx-1 bg-gray-100 rounded text-sm">
        {text}
      </code>
    )
  },
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => (
      <Button version="hyperlink" externalLink={node.data.uri}>
        {children}
      </Button>
    ),
    [BLOCKS.HEADING_1]: (node, children) => (
      <h1 className="heading-1">{children}</h1>
    ),
    [BLOCKS.HEADING_2]: (node, children) => {
      const plainText = getPlainTextFromHeader(node.content);
      return (
        <h2 className="heading-2" id={slugify(plainText)}>
          {children}
        </h2>
      );
    },
    [BLOCKS.HEADING_3]: (node, children) => (
      <h3 className="heading-3">{children}</h3>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <h4 className="heading-4">{children}</h4>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
      <h5 className="heading-5">{children}</h5>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
      <h6 className="heading-6">{children}</h6>
    ),

    [BLOCKS.OL_LIST]: (node, children) => (
      <ol className="list-decimal">{children}</ol>
    ),
    [BLOCKS.UL_LIST]: (node, children) => <ul className="list">{children}</ul>,

    [BLOCKS.LIST_ITEM]: (node, children) => (
      <li className="list-element">{children}</li>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => <p className="body">{children}</p>,
    [BLOCKS.QUOTE]: (children) => (
      <blockquote className="quote">
        <>"{children.content[0].content[0].value}"</>
      </blockquote>
    ),
    [BLOCKS.HR]: () => <hr className="hr" />,
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      return <Image file={node.data.target} />;
    }
  }
};

const ContentfulRichText = ({ richText }) => {
  const contentfulRichText = renderRichText(richText, options);
  return (
    <div id="richText" className="rich-text">
      {contentfulRichText}
    </div>
  );
};

export default ContentfulRichText;
