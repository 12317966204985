/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';

import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { removeNodes } from '../utils/getters';
import { scrollToNode } from '../utils/dom';

import ContentfulRichText from './ContentfulRichText';
import HeadingOne from './HeadingOne';
import TableOfContents, { getHeadersFromRichText } from './TableOfContents';
import Header from './Header';
import Sections from './Sections';
import Buttons from './Buttons';
import Faqs from './Faqs';

function Article({ data }) {
  const { content: article, relatedArticles } = data;

  const isTableOfContent =
    getHeadersFromRichText(renderRichText(article?.richText)).length > 0;

  useEffect(() => {
    scrollToNode('#richText', { offset : -180 });
  },[]);

  return (
    <>
      {article && (
        <>
          <Header articles={[article]} isArticlePage />
          <div className="wrapper">
            <div className="two-column">
              <div className="two-column__container">
                {isTableOfContent && (
                  <div className="two-column__side-panel">
                    <TableOfContents richText={article.richText} />
                    {article?.buttons && (
                      <div className="two-column__side-panel__cta">
                        <Buttons buttons={article.buttons} />
                      </div>
                    )}
                  </div>
                )}
                <div className="main border-bottom">
                  <HeadingOne content={article.title}/>
                  <ContentfulRichText richText={article.richText} />
                  {article?.publishedAt && (
                    <>
                      <figcaption className="body --x-small --light">
                        {article.title}
                      </figcaption>
                      <figcaption className="body --x-small --light">
                        {article.publishedAt}, Hoterie
                      </figcaption>
                    </>
                  )}
                </div>
              </div>
              {article.faqs && (
                <Faqs faqs={article.faqs} />
              )}
              {!article?.isNotArticle && (
                <>
                  <Sections
                    sections={removeNodes(relatedArticles?.edges)}
                    title="RELATED ARTICLES"
                  />
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Article;
