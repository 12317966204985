import React, { useReducer } from 'react';

import { SearchbarContext } from '../searchbar';
import { TOGGLE_SEARCHBAR, UPDATE_QUERY } from '../../utils/variables';


export const SearchbarProvider = ({ children }) => {
  const initialState = {
    isSearchbar: false,
    query: '',
  };

  function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case TOGGLE_SEARCHBAR:
        return { ...state, query: '', isSearchbar: payload || !state.isSearchbar };
      case UPDATE_QUERY:
        return { ...state, query: payload };
      default:
        return state;
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <SearchbarContext.Provider value={{ state, dispatch }}>
      {children}
    </SearchbarContext.Provider>
  );
};
