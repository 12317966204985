import React, { useLayoutEffect, useState } from 'react';

import { ViewportContext } from '../viewport';
import {
  MOBILE,
  TABLET,
  DESKTOP,
  TABLET_LARGE,
  DESKTOP_SMALL,
  BREAKPOINT_MOBILE,
  BREAKPOINT_TABLET,
  BREAKPOINT_LARGE_TABLET,
  BREAKPOINT_SMALL_DESKTOP
} from '../../utils/variables';

export const ViewportProvider = ({ children }) => {
  const isBrowser = () => typeof window !== 'undefined';
  const [width, setWidth] = useState(isBrowser() ? window.innerWidth : null);
  const [device, setDevice] = useState(null);

  useLayoutEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize, { passive: true });
    setDevice(
      width <= BREAKPOINT_MOBILE
        ? MOBILE
        : width <= BREAKPOINT_TABLET
        ? TABLET
        : width <= BREAKPOINT_LARGE_TABLET
        ? TABLET_LARGE
        : width <= BREAKPOINT_SMALL_DESKTOP
        ? DESKTOP_SMALL
        : DESKTOP
    );
    return () => window.removeEventListener('resize', handleWindowResize);
  }, [width]);

  const isMobile = device === MOBILE;
  const isTabletSmall = device === TABLET;
  const isDesktop = device === DESKTOP;
  const isSmallScreen = isMobile || isTabletSmall;
  const isTablet = !isDesktop && !isMobile;

  return (
    <ViewportContext.Provider
      value={{
        isMobile,
        isDesktop,
        isTabletSmall,
        isTablet,
        isSmallScreen,
        device
      }}
    >
      {children}
    </ViewportContext.Provider>
  );
};
