/* eslint-disable no-nested-ternary */
import React from 'react';
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image';

function Image({ file, objectFit = 'cover' }) {
  const imageAlt = file?.description || file?.title || 'Hoterie Image';
  const image = getImage(file);
  const src = getSrc(file) || file?.file?.url;

  return (
    <>
      {image ? (
        <GatsbyImage
          image={image}
          className="img"
          alt={imageAlt}
          objectFit={objectFit}
        />
      ) : src ? (
        <img
          loading="lazy"
          className="img"
          src={src}
          alt={imageAlt}
          width="600"
          height="400"
        />
      ) : null}
    </>
  );
}

export default Image;
