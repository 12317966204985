import { REGEX_REMOVE_PARENTHESIS_AND_INNER_CONTENT } from './regex';

// UTILS
export const getCamelCase = (str) =>
  str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
export const getArrayWithoutDuplicates = (arr) => {
  return arr.filter((item, index) => arr.indexOf(item) === index);
};
export const getTruncateString = (str, num) => {
  if (str.length <= num) {
    return str;
  }
  return `${str.slice(0, num)}...`;
};

export const getStringWithoutParenthesisAndInnerContent = (string) =>
  string.replace(REGEX_REMOVE_PARENTHESIS_AND_INNER_CONTENT, '').trim();

export const getArrayWithoutDuplicate = (arr, key) =>
  arr.filter((v, i, a) => a.findIndex((t) => t[key] === v[key]) === i);

export const getRandomId = () => Math.floor(Math.random() * 10000 + 1);

export const getRandomNumberWithinRange = (min, max) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

export const getTodaysDate = () => {
  let today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  const yyyy = today.getFullYear();

  return yyyy + '-' + mm + '-' + dd;
};

export const getEvenNumber = (i) => {
  return i % 2 === 0;
};

export const getArrayFromObject = (obj) => Object.values(obj);

export const getKeysWithPointInsteadOfUnderscore = (object) =>
  Object.keys(object).reduce((acc, key) => {
    const newKey = key.replace(/_/g, '.');
    acc[newKey] = object[key];
    return acc;
  }, {});

export const getFormattedString = (str) => {
  // Replace non-word characters with a space
  str = str?.replace(/\W/g, ' ').toUpperCase();

  return str;
};

export const isMultipleOf = (i, n) => {
  return i % n === 0 && i !== 0;;
};

// STYLES
export const getContentClassNames = (content) =>
  `${!content.title ? '--without-title' : ''} ${
    !content.subtitle ? '--without-subtitle' : ''
  } ${!content.description ? '--without-description' : ''} ${
    !content.buttons ? '--without-cta' : ''
  } `;

export const getClassNames = (classNames) =>
  classNames?.length > 0
    ? classNames?.map((className) => `${className}`).join(' ')
    : '';

// URLS
export const getUtm = (source, medium, campaign) => {
  return `?utm_source=${source}&utm_medium=${medium}&utm_campaign=${campaign}`;
};

export const getSlug = (string) => string?.toLowerCase().replace(' ', '-');

//ID
export const getSectionId = (element) => {
  return element?.identifier || `id-${element?.contentful_id}` || '';
};

//ADDRESSES
export const getAddressComponentType = (array, string) =>
  array?.find((c) => c.types.find((t) => t === string));

export const getIllustrationSide = (index) =>
  index % 2 === 0 ? 'left' : 'right';

export const getIsString = (element) => typeof element === 'string';

export const removeNodes = (array) => array?.map(({node}) => node);
