/* eslint-disable no-nested-ternary */
import React from 'react';

import Section from './Section';
import HeadingThree from './HeadingThree';

function Sections({ sections, title }) {
  return (
    <>
      {sections?.length > 0 && (
        <div className="sections" id="sections">
          {title && <HeadingThree content={title?.toUpperCase()} />}
          <div className="sections__container">
            {sections?.map((section, index) => {
              return (
                <React.Fragment key={section?.id}>
                  <Section section={section} index={index}/>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}

export default Sections;
