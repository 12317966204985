import React, { useContext, useEffect } from 'react';
import { FormattedMessage as Trans } from 'react-intl';
import {
  SEARCH_PAGE_CONTENTFUL_ID,
  BLOG_PAGE_CONTENTFUL_ID,
  HOTERIES_PAGE_CONTENTFUL_ID,
  CONTACT_FORM_PAGE_CONTENTFUL_ID,
  AIRBNB_HOTERIE_PROFILE
} from '../utils/variables';

import { useNavbar } from '../hooks/useNavbar';
import { usePaths } from '../hooks/usePaths';
import { ViewportContext } from '../contexts/viewport';

import HoterieLogo from './HoterieLogo';
import Button from './Button';
import SocialNetworks from './SocialNetworks';
import Navlinks from './Navlinks';
import Icon from './Icon';

const Navbar = () => {
  const { getPagePathByContentfulId } = usePaths();
  const { isSmallScreen } = useContext(ViewportContext);
  const { scroll, isBurgerMenu, onClickBurger } = useNavbar();

  const isHidden = isBurgerMenu || isSmallScreen;

  return (
    <nav
      id="navbar"
      className={`navbar ${scroll ? '--scroll' : ''} ${
        isBurgerMenu ? '--expanded' : ''
      }`}
      role="banner"
      itemScope
      itemType="http://schema.org/WPHeader"
      data-module="menu"
    >
      <div className="navbar__top">
        <div className="navbar__left">
          <HoterieLogo color={scroll || isBurgerMenu ? 'black' : 'white'} />
          {!isHidden && (
            <>
              <Button
                internalLink={getPagePathByContentfulId(
                  BLOG_PAGE_CONTENTFUL_ID
                )}
                version="navbar-link"
                label={<Trans id="hotestories" />}
              />
              <Button
                internalLink={getPagePathByContentfulId(
                  HOTERIES_PAGE_CONTENTFUL_ID
                )}
                version="navbar-link"
                label={<Trans id="hoteries" />}
              />
              <Button
                internalLink={getPagePathByContentfulId(
                  CONTACT_FORM_PAGE_CONTENTFUL_ID
                )}
                version="navbar-link"
                label={<Trans id="ctaRent" />}
                ariaLabel="Rent with Hoterie button"
              />
              <Button
                externalLink={AIRBNB_HOTERIE_PROFILE}
                version="navbar-link"
                label={<Trans id="ctaBook" />}
                ariaLabel="Book with Hoterie button"
              />
            </>
          )}
        </div>
        <>
          <div className="navbar__right">
            <Button
              internalLink={getPagePathByContentfulId(
                SEARCH_PAGE_CONTENTFUL_ID
              )}
              version="navbar-link"
              label={<Icon icon="MdSearch" />}
              ariaLabel="Search button"
            />
            <Button
              onClick={onClickBurger}
              version="navbar-link"
              label={<Icon icon={isBurgerMenu ? 'MdClose' : 'MdMenu'} />}
              ariaLabel="Burger button"
            />
            <SocialNetworks />
          </div>
        </>
      </div>
      {isBurgerMenu && (
        <div className="navbar__navlinks">
          <Navlinks />
        </div>
      )}
    </nav>
  );
};

export default Navbar;
