import { useContext, useState, useEffect } from 'react';
import Fuse from 'fuse.js';

import { TOGGLE_SEARCHBAR, UPDATE_QUERY } from '../utils/variables';
import { SearchbarContext } from '../contexts/searchbar';

export const useSearchbar = (searchItems = []) => {
  const [list, setList] = useState([]);
  const { state, dispatch } = useContext(SearchbarContext);
  const query = state?.query;
  const isSearchbar = state?.isSearchbar;

  const toggleSearchbar = (payload) => {
    dispatch({
      type: TOGGLE_SEARCHBAR,
      payload: payload || null
    });
  };

  const updateQuery = (query) => {
    dispatch({
      type: UPDATE_QUERY,
      payload: query
    });
  };



  useEffect(() => {
    const search = () => {
      const fuse = new Fuse(searchItems, {
        keys: ["digest", "title", "category"]
      });
      const result = fuse.search(query);
      const finalResult = [];
      if (result.length) {
        result.forEach((item) => {
          finalResult.push(item.item);
        });
        setList(finalResult);
      } else {
        setList(list);
      }
    };

    search();
  }, [query]);

  return {
    updateQuery,
    toggleSearchbar,
    query,
    isSearchbar,
    list
  };
};
