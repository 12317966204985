import React from 'react';
import { FormattedMessage as Trans } from 'react-intl';
import { useReadingTime } from 'react-hook-reading-time';

import Icon from './Icon';

export default function ReadingTime({ richTextRaw }) {
  const minutes = richTextRaw ? useReadingTime(richTextRaw).minutes : null;
  return (
    <>
      {minutes && (
        <>
          <span class="reading-time__icon">
            <Icon icon="MdAccessTime" additionalClassNames="--left" />
          </span>
          <span><Trans id="readIn"/> {minutes} minutes</span>
        </>
      )}
    </>
  );
}
