import axios from 'axios';
import qs from 'qs';

const GETFORM_ENDPOINTS = {
  contactForm: `https://getform.io/f/807d5085-6cdd-4c4e-80e2-b1cfc1d03274`
};

const postGetFormData = (url, data) =>
  axios
    .request({
      url,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: qs.stringify(data),
      method: 'POST'
    })
    .catch((err) => {
      console.log(err);
    });

export const GetFormApi = {
  contactForm(data) {
    return postGetFormData(GETFORM_ENDPOINTS.contactForm, data);
  },
};
