// BREAKPOINTS
export const BREAKPOINT_MOBILE = 467;
export const BREAKPOINT_TABLET = 768;
export const BREAKPOINT_LARGE_TABLET = 992;
export const BREAKPOINT_SMALL_DESKTOP = 1260;

// SOCIAL NETWORKS
export const INSTAGRAM = 'https://www.instagram.com/therealhoterie/';
export const LINKEDIN = 'https://www.linkedin.com/company/hoterie/about/';
export const FACEBOOK = 'https://www.facebook.com/Hoterie';
export const TWITTER = 'https://twitter.com/hoterie';

// SCROLL
export const PIXELS_FOR_NAVBAR_SCROLL_STATE = 100;
export const PIXELS_TO_SCROLL_TO_SHOW_SCROLL_TO_TOP_BUTTON = 1000;

//DEVICES
export const MOBILE = 'mobile';
export const TABLET = 'tablet';
export const TABLET_LARGE = 'tablet large';
export const DESKTOP_SMALL = 'desktop small';
export const DESKTOP = 'desktop';

//NAVBAR
export const SWITCH_NAVBAR_STATE = 'SWITCH_NAVBAR_STATE';
export const SET_NAVBAR_STATE_PROP = 'SET_NAVBAR_STATE_PROP';

//SEARCHBAR
export const UPDATE_QUERY = 'UPDATE_QUERY';
export const TOGGLE_SEARCHBAR = 'TOGGLE_SEARCHBAR';

// PAGES
export const SEARCH_PAGE_CONTENTFUL_ID = "2czGXDzu2kfbvyunh03Bjq"
export const BLOG_PAGE_CONTENTFUL_ID = "OZ8HRDdilaOFpPnZplx8K"
export const HOTERIES_PAGE_CONTENTFUL_ID = "55UBmAq9RechBnC7PEAWAG"
export const HOME_OWNER_PAGE_CONTENTFUL_ID = "1cuTrEzgEGgwA2BXzVdDrc"
export const GUEST_PAGE_CONTENTFUL_ID = "6k0BnvXbuua9ULPb1uTfP3"
export const FOUR_TWO_FOUR_PAGE_CONTENTFUL_ID = "15fI9PVqubHWrBNeb1Dz6W"
export const PARIS_PAGE_CONTENTFUL_ID = "6QlaR4CjgZ0v0SNauur2hY"
export const TERMS_PAGE_CONTENTFUL_ID = "2SZZsMdwTjD3JmiQsBgkYE"
export const CONTACT_FORM_PAGE_CONTENTFUL_ID = "3Jrgo393zNwQ2bYCZtoFLK"
export const FOOD_AND_DRINKS_PAGE_CONTENTFUL_ID = "1RuNuAwI2Ff9MEv7JTn2Ka"
export const UNDERGROUND_PAGE_CONTENTFUL_ID = "4pUkPZEeE1064TEXlZcUKX"
export const ART_PAGE_CONTENTFUL_ID = "4wHhiibRRjiMk7jMBdDWt8"
export const DESIGN_PAGE_CONTENTFUL_ID = "227giVUskpkuzvWh60Ev5U"
export const COUTURE_PAGE_CONTENTFUL_ID = "3UI5u3BuH4EN1Rx2c9pmN2"
export const LOCAL_LIFE_PAGE_CONTENTFUL_ID = "30xWIzS8K9GlqVCZMCHQIF"


//EMAIL PREFERENCES
export const ONE_HOME = 'one';
export const TWO_TO_FIVE_HOMES = 'two to five';
export const MORE_THAN_FIVE_HOMES = 'more than five';
export const NOT_INTERESTED = 'shipment emails only';
export const OTHER = 'other';
export const ALL_OPTED_IN = 'all opted in';
export const ALL_OPTED_OUT = 'opted out';
export const PARTLY_OPTED_IN = 'partly opted in';
export const SWITCH_PREFERENCES = 'SWITCH_PREFERENCES';
export const SWITCH_PREFERENCE = 'SWITCH_PREFERENCE';
export const SET_PREFERENCES = 'SET_PREFERENCES';
export const SET_STEP = 'SET_STEP';
export const UPDATE_OPT_IN_STATUS = 'UPDATE_OPT_IN_STATUS';

//EXTERNAL LINKS
export const AIRBNB_HOTERIE_PROFILE = "https://www.airbnb.fr/users/show/544081308"
