import { graphql } from 'gatsby';

import PageContainer from '../../../containers/PageContainer';

export default PageContainer;

export const pageQuery = graphql`
  query PageQuery($id: String, $node_locale: String) {
    site: site {
      siteMetadata {
        languages {
          langs
        }
      }
    }
    content: contentfulPage(
      id: { eq: $id }
      node_locale: { eq: $node_locale }
    ) {
      ...PageFragment
    }
    allArticles: allContentfulArticle(
      filter: { node_locale: { eq: $node_locale }, isNotArticle: { ne: true } }
      sort: { createdAt: DESC }
    ) {
      edges {
        node {
          ...ArticleFragment
        }
      }
    }

    allFaqs: allContentfulFaq(filter: { node_locale: { eq: $node_locale } }) {
      edges {
        node {
          ...FaqFragment
        }
      }
    }
  }
`;
