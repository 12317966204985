import React, { useState } from 'react';

import { SitePathsContext } from '../sitePaths';

export const SitePathsProvider = ({ children }) => {
  const [pageContentfulId, setPageContentfulId] = useState([]);

  return (
    <SitePathsContext.Provider
      value={{
        pageContentfulId,
        setPageContentfulId
      }}
    >
      {children}
    </SitePathsContext.Provider>
  );
};
