import React from 'react';
import { QueryParamProvider } from 'use-query-params';

import { ViewportProvider } from './src/contexts/providers/ViewportProvider';
import { LanguageProvider } from './src/contexts/providers/LanguageProvider';
import { NavbarProvider } from './src/contexts/providers/NavbarProvider';
import { SitePathsProvider } from './src/contexts/providers/SitePathsProvider';
import { SearchbarProvider } from './src/contexts/providers/SearchbarProvider';
import { ContactFormProvider } from './src/contexts/providers/ContactFormProvider';
import './src/assets/stylesheets/app.scss';

export const wrapRootElement = ({ element }) => (
  <LanguageProvider>
    <NavbarProvider>
      <SearchbarProvider>
        <ContactFormProvider>
          <ViewportProvider>
            <QueryParamProvider>
              <SitePathsProvider>{element}</SitePathsProvider>
            </QueryParamProvider>
          </ViewportProvider>
        </ContactFormProvider>
      </SearchbarProvider>
    </NavbarProvider>
  </LanguageProvider>
);

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`);
    console.log(`# IntersectionObserver is polyfilled!`);
  }
};
