import React from 'react';
import { FormattedMessage as Trans } from 'react-intl';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import slugify from 'slugify';

import Button from './Button';
import HeadingFour from './HeadingFour';
import { getIsString } from '../utils/getters';

export const getHeadersFromRichText = (richText) => {
  const headers = (content) => content.type === 'h2';

  return richText.filter(headers).map((heading) => {
    const plainText =
      heading?.props?.children[0]?.props?.children ||
      heading?.props?.children[0];
    return {
      text: plainText,
      hash: `#${getIsString(plainText) ? slugify(plainText) : '#'}`
    };
  });
};

const TableOfContents = ({ richText }) => {
  return (
    <>
      <div className="table-of-contents">
        <HeadingFour content={<Trans id="tableOfContentTitle"/>} />
        <ol>
          {getHeadersFromRichText(renderRichText(richText)).map((item, i) => (
            <li key={i}>
              <Button
                version={'table-of-content-link'}
                internalLink={item?.hash}
                label={item?.text}
              />
            </li>
          ))}
        </ol>
      </div>
    </>
  );
};

export default TableOfContents;
