import React from 'react';
import { MdMenu } from '@react-icons/all-files/md/MdMenu';
import { MdClose } from '@react-icons/all-files/md/MdClose';
import { MdSearch } from '@react-icons/all-files/md/MdSearch';
import { MdArrowDropDown } from '@react-icons/all-files/md/MdArrowDropDown';
import { MdArrowBack } from '@react-icons/all-files/md/MdArrowBack';
import { MdArrowForward } from '@react-icons/all-files/md/MdArrowForward';
import { MdHelpOutline } from '@react-icons/all-files/md/MdHelpOutline';
import { MdKeyboardArrowDown } from '@react-icons/all-files/md/MdKeyboardArrowDown';
import { MdKeyboardArrowUp } from '@react-icons/all-files/md/MdKeyboardArrowUp';
import { MdAccessTime } from '@react-icons/all-files/md/MdAccessTime';
import { FaLinkedinIn } from '@react-icons/all-files/fa/FaLinkedinIn';
import { FaFacebookF } from '@react-icons/all-files/fa/FaFacebookF';
import { FaInstagram } from '@react-icons/all-files/fa/FaInstagram';

const components = {
  MdMenu,
  MdClose,
  MdArrowDropDown,
  MdSearch,
  FaInstagram,
  FaLinkedinIn,
  FaFacebookF,
  MdArrowForward,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdArrowBack,
  MdAccessTime
};

export default function Icon({ icon, additionalClassNames }) {
  if (!components[icon]) {
    console.log(`Icon ${icon} not found`);
  }
  const Icon = components[icon] || MdHelpOutline;
  return <Icon icon={icon} className={`icon ${additionalClassNames}`} />;
}
