import React, { useRef } from 'react';

import { useSearchbar } from '../hooks/useSearchbar';

import Button from './Button';
import Icon from './Icon';

const Searchbar = ({isSecondary, isSmall}) => {
  const { updateQuery, query } = useSearchbar();

  const inputRef = useRef();

  const handleChange = (e) => {
    updateQuery(e.currentTarget.value);
  };

  const style = `searchbar${isSecondary ? ' --secondary' : ''}${isSmall ? ' --small' : ''}`

  return (
    <div className={style}>
      <Button
        ariaLabel="Search icon"
        label={<Icon icon="MdSearch" />}
        version="wrapper"
        onClick={() => inputRef?.current?.focus()}
      />
      <input
        ref={inputRef}
        type="text"
        className="searchbar__input"
        value={query}
        onChange={handleChange}
      />
    </div>
  );
};

export default Searchbar;
