import React from 'react';
import { graphql } from 'gatsby';

export default function RedirectIndex(args) {
  if (typeof window !== 'undefined') {
    const { content } = args.data;
    window.___replace(content?.gatsbyPath);
  }
  return <div />;
}

export const pageQuery = graphql`
  query IndexQuery($id: String, $node_locale: String) {
    site: site {
      siteMetadata {
        languages {
          langs
        }
      }
    }
    content: contentfulPage(
      id: { eq: $id }
      contentful_id: { eq: "6QlaR4CjgZ0v0SNauur2hY" }
      node_locale: { eq: $node_locale }
    ) {
      ...PageFragment
    }
  }
`;
