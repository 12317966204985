import { useContext, useEffect } from 'react';
import { useLocation } from '@reach/router';

import { LanguageContext } from '../contexts/language';

export const useLanguage = () => {
  const location = useLocation();

  const {
    setLangKey,
    language,
    langKey,
    langs,
    defaultLangKey,
    remainingLanguages,
    langMap,
    getLangKeyFromUrl,
    getLanguageFromLangKey,
    getRemainingLanguages,
    getLangKeyFromBrowserSettings
  } = useContext(LanguageContext);
  useEffect(() => {
    setLangKey(getLangKeyFromUrl(location.pathname));
  }, []);

  return {
    language,
    langKey,
    defaultLangKey,
    langs,
    remainingLanguages,
    langMap,
    getLangKeyFromUrl,
    getLanguageFromLangKey,
    getRemainingLanguages,
    getLangKeyFromBrowserSettings
  };
};
