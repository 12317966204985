import React from 'react';

import { FormattedMessage as Trans } from 'react-intl';

import { scrollToTop } from '../utils/dom';
import { useDetectScroll } from '../hooks/useDetectScroll';
import { PIXELS_TO_SCROLL_TO_SHOW_SCROLL_TO_TOP_BUTTON } from '../utils/variables';

import Button from './Button';

export default function ButtonScrollToTop() {
  // Set scroll button appearing
  const { scroll } = useDetectScroll(
    PIXELS_TO_SCROLL_TO_SHOW_SCROLL_TO_TOP_BUTTON
  );
  return (
    <>
      {scroll && (
        <Button
          onClick={() => scrollToTop()}
          version="light"
          label={<Trans id="ctaBackToTop" />}
          additionalClassNames="--scroll-to-top"
        />
      )}
    </>
  );
}
