import React from 'react';

import { FACEBOOK, INSTAGRAM, LINKEDIN, TWITTER } from '../utils/variables';

import Button from './Button';
import Icon from './Icon';

export default function SocialNetworks({ version = '' }) {
  const links = [
    { href: INSTAGRAM, name: 'instagram', icon: 'FaInstagram' },
    // { href: LINKEDIN, name: 'linkedin', icon: 'FaLinkedinIn' },
    // { href: FACEBOOK, name: 'facebook', icon: 'FaFacebookF' }
  ];
  return (
    <>
      <div className={`social-networks ${version}`}>
        {links?.map((link) => (
          <React.Fragment key={link.name}>
            <Button
              label={<Icon icon={link.icon} />}
              externalLink={link.href}
              version='navbar-link'
              ariaLabel={`Hoterie ${link.name}`}
            />
          </React.Fragment>
        ))}
      </div>
    </>
  );
}
