import { graphql } from 'gatsby';

import ArticleContainer from '../../../../containers/PageContainer';

export default ArticleContainer;

export const articleQuery = graphql`
  query ArticleQuery($id: String, $node_locale: String, $category: String) {
    site: site {
      siteMetadata {
        languages {
          langs
        }
      }
    }
    content: contentfulArticle(
      id: { eq: $id }
      node_locale: { eq: $node_locale }
    ) {
      ...ArticleFragment
    }

    relatedArticles: allContentfulArticle(
      filter: { node_locale: { eq: $node_locale }, category: { eq: $category }, id: { ne: $id }}
    ) {
      edges {
        node {
          ...ArticleFragment
        }
      }
    }

    newestArticles: allContentfulArticle(
      filter: { node_locale: { eq: $node_locale }, id: { ne: $id }}
      sort: { publishedAt: DESC }
      limit: 3
    ) {
      edges {
        node {
          ...ArticleFragment
        }
      }
    }
  }
`;
