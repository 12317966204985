import React from 'react';
import Logo from '../assets/images/svg/hoterie.svg'


import { usePaths } from '../hooks/usePaths';
import { PARIS_PAGE_CONTENTFUL_ID } from '../utils/variables';

import Button from './Button';

export default function HoterieLogo({
  color = 'black',
  additionalClassNames = ''
}) {
  const { getPagePathByContentfulId } = usePaths();

  return (
    <>
      <div className={`hoterie-logo --${color} ${additionalClassNames}`}>
        <Button
          version="navbar-link"
          label="HOTERIE"
          internalLink={getPagePathByContentfulId(PARIS_PAGE_CONTENTFUL_ID)}
        >
          <Logo/>
        </Button>
      </div>
    </>
  );
}
