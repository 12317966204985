import React from 'react';

import { getTruncateString } from '../utils/getters';

export default function Digest({ content }) {
  return (
    <>
      {content && (
        <p className="digest">
          {getTruncateString(content, 800)}
        </p>
      )}
    </>
  );
}
