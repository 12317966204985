import { useStaticQuery, graphql } from 'gatsby';
import { useLanguage } from './useLanguage';

export const useUseStaticQuery = () => {
  const { langKey } = useLanguage();

  const { allPages, allArticles, commonContent, defaultCoverVideo, defaultOgImage } = useStaticQuery(
    graphql`
      query content {
        allPages: allContentfulPage {
          edges {
            node {
              ...PageFragment
            }
          }
        }
        allArticles: allContentfulArticle {
          edges {
            node {
              ...ArticleFragment
            }
          }
        }
        commonContent: allContentfulCommonContent {
          nodes {
            id
            node_locale
            translations {
              paris
              hotestories
              hoteries
              homeOwners
              guests
              terms
              helpCenter
              ctaLabelRead
              ctaFaqQuestion
              ctaBackToTop
              offices
              utils
              ctaBook
              ctaRent
              contactFormCta
              contactFormtooltipLabelAcceptConditions
              contactFormtooltipLabelFillInForm
              contactFormFirstNameTitle
              contactFormFirstNameLabel
              contactFormLastNameTitle
              contactFormLastNameLabel
              contactFormEmailTitle
              contactFormEmailLabel
              contactFormNumberTitle
              contactFormNumberLabel
              contactFormNumberOfHomesTitle
              contactFormNumberOfHomesLabelOneHome
              contactFormNumberOfHomesLabelTwoToFiveHomes
              contactFormNumberOfHomesLabelMoreThanFiveHomes
              contactFormDescriptionTitle
              contactFormDescriptionLabel
              tableOfContentTitle
              backToHomepageCta
              ctaCallUs
              externalLinkAirbnbHoterie
              externalLinkHoterieEmail
              readIn
            }
          }
        }
        defaultOgImage: contentfulAsset(contentful_id: { eq: "5XRhz59mD5WEDQLbKRV7sJ" }) {
          ...AssetFragment
        }
      }
    `
  );

  // Get content  for current lang
  const getTranslatedContent = (content) => {
    return content?.nodes?.find((node) => node.node_locale === langKey);
  };

  const { translations } = getTranslatedContent(commonContent);

  return {
    defaultOgImage,
    translations,
    allPages,
    allArticles
  };
};
