export const HOTERIE_KEYWORDS = {
  fr: [
    'Résidences de Luxe',
    'Gestion Immobilière',
    'Résidences Haut de Gamme',
    'Investissement Immobilier',
    'Maximisation du Potentiel',
    'Hébergements Exclusifs',
    'Services Immobiliers Premium',
    'Investissement sans Effort',
    'Rentabilité Immobilière',
    'Gestion sur Mesure',
    "Réalisation de Rendements d'Investissement",
    'Propriété Sans Contraintes',
    'Propriétés à Haute Valeur',
    'Art de Vivre Raffiné',
    'Optimisation Immobilière',
    "Gestion d'Investissement",
    'Amélioration Immobilière',
    'Immobilier Rentable',
    'Gestion de Portefeuille Immobilier',
    'Soin Premium des Biens Immobiliers',
    'Locations de Luxe à Paris',
    'Logements Airbnb Élégants',
    'Appartements Design à Paris',
    'Escapades Chic à Paris',
    'Séjours Premium Airbnb',
    'Hébergements Sophistiqués à Paris',
    'Locations de Vacances Élégantes à Paris',
    'Annonces Airbnb Haut de Gamme',
    'Retraites de Luxe Parisiennes',
    'Propriétés Airbnb Exquises',
    'Expériences Uniques Parisiennes',
    'Meilleurs Choix de Design Airbnb',
    'Séjours Haut de Gamme à Paris',
    'Excellence Airbnb à Paris',
    'Locations de Rêve Parisiennes',
    'Sélections Airbnb Raffinées',
    'Esthétique Airbnb Parisienne',
    'Hébergements Exclusifs à Paris',
    'Meilleurs Logements Design Airbnb à Paris',
    'Options Exceptionnelles Airbnb à Paris'
  ],
  en: [
    'Luxury Homes',
    'Property Management',
    'High-end Residences',
    'Real Estate Investment',
    'Maximizing Potential',
    'Exclusive Accommodations',
    'Premium Property Services',
    'Effortless Investment',
    'Property Profitability',
    'Bespoke Management',
    'Realizing Investment Returns',
    'Hands-Free Property Ownership',
    'High-Value Properties',
    'Sophisticated Living',
    'Property Optimization',
    'Investment Management',
    'Property Enhancement',
    'Profitable Real Estate',
    'Property Portfolio Management',
    'Premium Property Care',
    'Paris Luxury Rentals',
    'Stylish Airbnb Homes',
    'Designer Paris Apartments',
    'Chic Parisian Getaways',
    'Premium Airbnb Stays',
    'Sophisticated Paris Accommodations',
    'Elegant Paris Vacation Rentals',
    'High-End Airbnb Listings',
    'Luxury Parisian Retreats',
    'Exquisite Airbnb Properties',
    'Unique Parisian Experiences',
    'Top Airbnb Design Choices',
    'Upscale Paris Stays',
    'Paris Airbnb Excellence',
    'Parisian Dream Rentals',
    'Refined Airbnb Selections',
    'Airbnb Paris Aesthetics',
    'Exclusive Paris Accommodations',
    'Best-Designed Airbnb in Paris',
    'Exceptional Paris Airbnb Options'
  ]
};

export const HOTERIE_DESCRIPTION = {
  fr: "Hoterie : Élevez Votre Expérience Parisienne. Découvrez le summum des locations de luxe, des appartements design parisiens et des logements élégants Airbnb. Nous sommes spécialisés dans l'exploitation du potentiel de vos propriétés haut de gamme, offrant à nos invités des hébergements haut de gamme, chics et sophistiqués. Vivez l'excellence avec Hoterie, votre porte d'entrée vers les meilleurs séjours Airbnb à Paris, conçus avec raffinement.",
  en: 'Hoterie: Elevating Your Paris Experience. Discover the finest in luxury rentals, designer Paris apartments, and stylish Airbnb homes. We specialize in maximizing the potential of your high-end properties, offering guests premium, chic, and sophisticated accommodations. Experience excellence with Hoterie, your gateway to the best-designed Airbnb stays in Paris.'
};

export const HOTERIE_TITLE = {
  fr: 'Hoterie - Exploitons le potentiel de vos logements de prestige.',
  en: 'Hoterie - Revealing the full potential of your luxury homes.'
};
