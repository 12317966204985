import { useContext, useEffect } from 'react';

import { NavbarContext } from '../contexts/navbar';
import { ViewportContext } from '../contexts/viewport';
import {
  PIXELS_FOR_NAVBAR_SCROLL_STATE,
  SET_NAVBAR_STATE_PROP,
  SWITCH_NAVBAR_STATE
} from '../utils/variables';
import { useDetectScroll } from './useDetectScroll';

export const useNavbar = () => {
  const { scroll } = useDetectScroll(PIXELS_FOR_NAVBAR_SCROLL_STATE);
  const { state, dispatch } = useContext(NavbarContext);
  const { isNavbarTransparent, isBurgerMenu } =
    state;
  const { isDesktop } = useContext(ViewportContext);

  // NAVBAR STATE CHANGE FUNCTIONS
  const onClickBurger = () => {
    dispatch({
      type: SWITCH_NAVBAR_STATE,
      payload: 'isBurgerMenu'
    });
  };

  const setIsNavbarTransparent = (value) => {
    dispatch({
      type: SET_NAVBAR_STATE_PROP,
      payload: {
        prop: 'isNavbarTransparent',
        value: value
      }
    });
  };

  useEffect(() => {
    dispatch({
      type: SET_NAVBAR_STATE_PROP,
      payload: { prop: 'isBurgerMenu', value: false }
    });
  }, []);

  return {
    scroll,
    isNavbarTransparent,
    isBurgerMenu,
    onClickBurger,
    setIsNavbarTransparent,
  };
};
