import { useCallback, useContext, useState, useEffect } from 'react';

import { ContactFormContext } from '../contexts/contactForm';

import { GetFormApi } from '../utils/api';
import { SET_PREFERENCES } from '../utils/variables';

export const useContactForm = () => {
  const { state, dispatch } = useContext(ContactFormContext);
  const {
    firstname,
    lastname,
    email,
    number,
    description,
    numberOfHomes,
    isLoading,
    isError,
    isContactFormSent
  } = state;

  const [isFormCompleted, setIsFormCompleted] = useState(false);

  //FUNCTIONS

  const setValue = (name, value) => {
    dispatch({
      type: SET_PREFERENCES,
      payload: { name, value }
    });
  };

  const sendContactForm = useCallback(() => {
    setValue('isLoading', true);

    GetFormApi.contactForm({
      firstname,
      lastname,
      email,
      number,
      description,
      numberOfHomes
    })
      .then((res) => {
        console.log(res);
        setValue('isContactFormSent', true);
        setValue('isLoading', false);
      })
      .catch((err) => {
        console.log(err);
        setValue('isError', true);
        setValue('isLoading', false);
      });
  }, [state]);

  useEffect(() => {
    setIsFormCompleted(email && firstname && lastname && number && description);
  }, [state]);

  return {
    firstname,
    lastname,
    number,
    description,
    email,
    isLoading,
    isError,
    isContactFormSent,
    setValue,
    isFormCompleted,
    numberOfHomes,
    sendContactForm
  };
};
