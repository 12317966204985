import React, { useContext } from 'react';
import { navigate } from 'gatsby';

import { ViewportContext } from '../contexts/viewport';
import { useLanguage } from '../hooks/useLanguage';
import { usePaths } from '../hooks/usePaths';

import Button from './Button';
import Dropdown from './Dropdown';

export default function DropdownLanguages() {
  const { langKey, langMap } = useLanguage();
  const { otherLanguagesPath } = usePaths();

  const handleClickLanguage = (path) => {
    navigate(path.gatsbyPath);
  };

  return (
    <>
      <Dropdown
      version='text-light'
      label={langMap[langKey]}
      >
        {otherLanguagesPath?.map((path) => (
          <React.Fragment key={path.id}>
            <Button
              onClick={() => handleClickLanguage(path)}
              version='text-dark'
              label={langMap[path.node_locale]}
              additionalClassNames='--dropdown'
            />

          </React.Fragment>
        ))}
      </Dropdown>
    </>
  );
}
