import React, { useEffect, useState } from 'react';
import { FormattedMessage as Trans } from 'react-intl';

function FormFieldText({
  onChange,
  value,
  id = null,
  name,
  label,
  type = 'text',
  required = true,
  additionalClassNames,
  isLarge
}) {
  const [isNotEmpty, setIsNotEmpty] = useState(value?.length > 0);

  const style = `form-field${isNotEmpty ? ' --not-empty' : ''}${
    isLarge ? ' --large' : ''
  } ${additionalClassNames}`;

  useEffect(() => {
    setIsNotEmpty(value?.length > 0);
  }, [value]);

  const inputProps = {
    id,
    type,
    name,
    className: 'form-field__input',
    placeholder: name,
    onChange,
    value: value || '',
    required: required,
    maxLength: isLarge ? 1000 : 50
  };
  return (
    <>
      <div className={style}>
        <div className="form-field__control">
          {isLarge ? <textarea {...inputProps} /> : <input {...inputProps} />}

          <label htmlFor={id} className="form-field__label">
            <Trans id={label} />
            ...{required ? ' *' : ''}
          </label>
        </div>
      </div>
    </>
  );
}

export default FormFieldText;
