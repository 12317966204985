import React from 'react';
import { injectIntl } from 'react-intl';
import { Link } from 'gatsby-link';

function Button({
  internalLink,
  externalLink,
  onClick,
  version = 'dark',
  ariaLabel,
  label,
  children,
  intl,
  isDisabled,
  isDownload,
  isActive,
  isLabelHtml,
  activeClassName = '--active',
  tabIndex,
  additionalClassNames = '',
  type = 'button'
}) {
  const style = `btn --${version} ${isDisabled ? '--disabled' : ''} ${
    isActive ? '--active' : ''
  } ${additionalClassNames}`;

  const ButtonLabel = () => (
    <>
      {isLabelHtml ? (
        <div
          dangerouslySetInnerHTML={{
            __html: label
          }}
        />
      ) : (
        children || label || null
      )}
    </>
  );

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    return;
  };

  const ariaLabelText = ariaLabel || label || 'Hoterie button';

  return (
    <>
      {internalLink && (
        <Link
          className={style}
          to={internalLink}
          activeClassName={activeClassName}
          aria-label={ariaLabelText}
          disabled={isDisabled}
          onClick={handleClick}
        >
          <ButtonLabel />
        </Link>
      )}

      {externalLink && (
        <a
          className={style}
          href={externalLink}
          target="_blank"
          rel="nofollow noreferrer"
          aria-label={ariaLabelText}
          onClick={handleClick}
        >
          <ButtonLabel />
        </a>
      )}

      {!internalLink && !externalLink && onClick && (
        <button
          className={style}
          type={type}
          onClick={handleClick}
          disabled={isDisabled}
          download={isDownload}
          aria-label={ariaLabelText}
          name={ariaLabel}
          tabIndex={tabIndex}
          role={internalLink}
        >
          <ButtonLabel />
        </button>
      )}
    </>
  );
}

export default injectIntl(Button);
