import { useLocation } from '@reach/router';

import {
  HOTERIE_DESCRIPTION,
  HOTERIE_KEYWORDS,
  HOTERIE_TITLE
} from '../utils/seo';

import { useLanguage } from './useLanguage';
import { useUseStaticQuery } from './useUseStaticQuery';

export const useSeoMetada = (data, isArticle) => {
  const { seoMetadata } = data.content;

  const location = useLocation();
  const { language } = useLanguage();
  const { defaultOgImage } = useUseStaticQuery();

  const keywords = HOTERIE_KEYWORDS[language];
  const description = isArticle
    ? data?.content?.digest?.childMarkdownRemark?.rawMarkdownBody
    : data?.content?.sections
    ? data?.content?.sections[0].digest?.childMarkdownRemark?.rawMarkdownBody
    : HOTERIE_DESCRIPTION[language];
  const title = isArticle
    ? data?.content?.title
    : data?.content?.sections
    ? data?.content?.sections[0].title
    : HOTERIE_TITLE[language];
  const ogImage = isArticle
    ? data?.content?.slideshow[0]
    : data?.content?.sections
    ? data?.content?.sections[0].coverImage
    : defaultOgImage;

  const helmetMetadata = [
    {
      name: 'keywords',
      content: keywords
    },
    {
      name: 'description',
      content: seoMetadata?.description || description
    },
    // Facebook Meta
    {
      property: 'og:site_name',
      content: 'HOTERIE'
    },
    {
      property: 'og:title',
      content: seoMetadata?.title || title
    },
    {
      property: 'og:description',
      content: seoMetadata?.description || description
    },
    {
      property: 'og:url',
      content: location?.href
    },
    {
      property: 'og:image',
      content: seoMetadata?.ogImage?.file?.url || ogImage?.file?.url
    },
    {
      property: 'og:type',
      content: 'website'
    },

    // Twitter Meta
    {
      name: 'twitter:card',
      content: 'summary_large_image'
    },
    {
      name: 'twitter:title',
      content: seoMetadata?.title || title
    },
    {
      name: 'twitter:description',
      content: seoMetadata?.description || description
    },
    {
      name: 'twitter:url',
      content: location?.href
    },
    {
      name: 'twitter:image',
      content: seoMetadata?.ogImage?.file?.url || ogImage?.file?.url
    }
  ].filter((x) => x != []);

  return { helmetMetadata };
};
