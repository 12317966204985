/* eslint-disable no-nested-ternary */
import React from 'react';

import ArticleCard from './ArticleCard';

function ArticleCards({ articles }) {
  return (
    <>
      {articles.length > 0 && (
        <div className="article-cards">
          <div className="article-cards__container">
            {articles?.map((node, i) => {
              const article = node?.node || node;
              return (
                <React.Fragment key={article?.id}>
                  <ArticleCard article={article}/>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}

export default ArticleCards;
