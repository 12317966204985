import React, { useState } from 'react';
import { FormattedMessage as Trans } from 'react-intl';

function Tooltip({ children, label }) {
  const [show, setShow] = useState(false);

  return (
    <>
      <div className="tooltip__container">
        <div
          className={`tooltip__box ${show ? '--visible' : ''} body --x-small`}
        >
          <Trans id={label} />
        </div>
        <div
          onMouseEnter={() => setShow(true)}
          onMouseLeave={() => setShow(false)}
        >
          {children}
        </div>
      </div>
    </>
  );
}

export default Tooltip;
