import React, { useEffect } from 'react';
import { FormattedMessage as Trans } from 'react-intl';
import BackgroundImage from 'gatsby-background-image';
import { convertToBgImage } from 'gbimage-bridge';

import { scrollToTop } from '../utils/dom';
import { useContactForm } from '../hooks/useContactForm';
import { getFormattedString } from '../utils/getters';

import FormFieldText from './FormFieldText';
import FormFieldRadioButton from './FormFieldRadioButton';
import BlockContent from './BlockContent';
import HeadingFour from './HeadingFour';
import {
  ONE_HOME,
  TWO_TO_FIVE_HOMES,
  MORE_THAN_FIVE_HOMES
} from '../utils/variables';

function PageContactForm({ data }) {
  const { sections } = data?.content;

  const {
    setValue,
    isLoading,
    sendContactForm,
    firstname,
    lastname,
    email,
    number,
    description,
    numberOfHomes,
    isFormCompleted,
    isContactFormSent,
    isError
  } = useContactForm();

  const buttons = [
    {
      onClick: sendContactForm,
      isDisabled: isLoading || !isFormCompleted,
      version: 'light',
      label: isContactFormSent ? (
        <Trans id="backToHomepageCta" />
      ) : isError ? (
        <Trans id="callUsCta" />
      ) : (
        <Trans id="contactFormCta" />
      ),
      type: 'submit',
      tooltipLabel: !isContactFormSent
        ? isFormCompleted
          ? 'contactFormtooltipLabelAcceptConditions'
          : 'contactFormtooltipLabelFillInForm'
        : null
    }
  ];

  const element = isError
    ? sections[2]
    : isContactFormSent
    ? sections[1]
    : sections[0];
  const elementObject = {
    id: element?.id,
    bgImage: convertToBgImage(element?.coverImage?.gatsbyImageData),
    title: element?.title,
    category: element?.category,
    digest: element?.digest
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <React.Fragment key={elementObject.id}>
        <BackgroundImage {...elementObject?.bgImage}>
          <header className="header --contact-form --background-overlay">
            <div className="wrapper">
              <div className="header__container">
                <BlockContent
                  content={{
                    headingFour: getFormattedString(elementObject?.category),
                    headingTwo: elementObject?.title,
                    digest:
                      elementObject?.digest?.childMarkdownRemark
                        ?.rawMarkdownBody,
                    buttons
                  }}
                />
                {!isContactFormSent && (
                  <form
                    noValidate
                    className="form"
                    data-module="form"
                    name="contact-form"
                    method="post"
                    onSubmit={sendContactForm}
                  >
                    <div className="form-row">
                      <div className="form-row__container">
                        <HeadingFour
                          content={<Trans id="contactFormFirstNameTitle" />}
                        />
                        <FormFieldText
                          onChange={(e) =>
                            setValue('firstname', e.target.value)
                          }
                          value={firstname}
                          id={`contact-form-firstname-input`}
                          name="firstname"
                          label="contactFormFirstNameLabel"
                          additionalClassNames={'--text-white'}
                        />
                      </div>
                      <div className="form-row__container">
                        <HeadingFour
                          content={<Trans id="contactFormLastNameTitle" />}
                        />
                        <FormFieldText
                          onChange={(e) => setValue('lastname', e.target.value)}
                          value={lastname}
                          id={`contact-form-lastname-input`}
                          name="lastname"
                          label="contactFormLastNameLabel"
                          additionalClassNames={'--text-white'}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-row__container">
                        <HeadingFour
                          content={<Trans id="contactFormEmailTitle" />}
                        />
                        <FormFieldText
                          onChange={(e) => setValue('email', e.target.value)}
                          value={email}
                          id={`contact-form-email-input`}
                          name="email"
                          label="contactFormEmailLabel"
                          type="email"
                          additionalClassNames={'--text-white'}
                        />
                      </div>
                      <div className="form-row__container">
                        <HeadingFour
                          content={<Trans id={'contactFormNumberTitle'} />}
                        />
                        <FormFieldText
                          onChange={(e) => setValue('number', e.target.value)}
                          value={number}
                          id={`contact-form-number-input`}
                          name="number"
                          label="contactFormNumberLabel"
                          type="number"
                          additionalClassNames={'--text-white'}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-row__container">
                        <HeadingFour
                          content={
                            <Trans id={'contactFormNumberOfHomesTitle'} />
                          }
                        />
                        <div className="form-row__radio-buttons">
                          <FormFieldRadioButton
                            formName="numberOfHomes"
                            name={ONE_HOME}
                            value={numberOfHomes === ONE_HOME}
                            onChange={() => setValue('numberOfHomes', ONE_HOME)}
                            label="contactFormNumberOfHomesLabelOneHome"
                          />
                          <FormFieldRadioButton
                            formName="numberOfHomes"
                            name={TWO_TO_FIVE_HOMES}
                            value={numberOfHomes === TWO_TO_FIVE_HOMES}
                            onChange={() =>
                              setValue('numberOfHomes', TWO_TO_FIVE_HOMES)
                            }
                            label="contactFormNumberOfHomesLabelTwoToFiveHomes"
                          />
                          <FormFieldRadioButton
                            formName="numberOfHomes"
                            name={MORE_THAN_FIVE_HOMES}
                            value={numberOfHomes === MORE_THAN_FIVE_HOMES}
                            onChange={() =>
                              setValue('numberOfHomes', MORE_THAN_FIVE_HOMES)
                            }
                            label="contactFormNumberOfHomesLabelMoreThanFiveHomes"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-row__container">
                        <HeadingFour
                          content={<Trans id={'contactFormDescriptionTitle'} />}
                        />
                        <FormFieldText
                          isLarge
                          onChange={(e) =>
                            setValue('description', e.target.value)
                          }
                          value={description}
                          id={`contact-form-description-input`}
                          name="description"
                          label="contactFormDescriptionLabel"
                          type="description"
                          additionalClassNames={'--text-white'}
                        />
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </header>
        </BackgroundImage>
      </React.Fragment>
    </>
  );
}

export default PageContactForm;
