import React from 'react';
import { graphql } from 'gatsby';

import { useSeoMetada } from '../hooks/useSeoMetadata';
import {
  BLOG_PAGE_CONTENTFUL_ID,
  SEARCH_PAGE_CONTENTFUL_ID,
  FOUR_TWO_FOUR_PAGE_CONTENTFUL_ID,
  TERMS_PAGE_CONTENTFUL_ID,
  HOTERIES_PAGE_CONTENTFUL_ID,
  CONTACT_FORM_PAGE_CONTENTFUL_ID
} from '../utils/variables';

import Layout from '../components/Layout';
import Page from '../components/Page';
import PageSearch from '../components/PageSearch';
import PageBlog from '../components/PageBlog';
import PageHoteries from '../components/PageHoteries';
import PageContactForm from '../components/PageContactForm';
import Article from '../components/Article';

const PageContainer = ({ data }) => {
  const isSearchPage =
    data?.content?.contentful_id === SEARCH_PAGE_CONTENTFUL_ID ||
    data?.content?.contentful_id === FOUR_TWO_FOUR_PAGE_CONTENTFUL_ID;
  const isBlogPage = data?.content?.contentful_id === BLOG_PAGE_CONTENTFUL_ID;
  const isHoteriesPage =
    data?.content?.contentful_id === HOTERIES_PAGE_CONTENTFUL_ID;
  const isArticle =
    data?.content?.sys?.contentType?.sys?.id === 'blogPost-2' ||
    data?.content?.contentful_id === TERMS_PAGE_CONTENTFUL_ID;

  const isContactFormPage =
    data?.content?.contentful_id === CONTACT_FORM_PAGE_CONTENTFUL_ID;


  const { helmetMetadata } = useSeoMetada(data, isArticle);

  return (
    <Layout data={data} helmetMetadata={helmetMetadata}>
      {isSearchPage ? (
        <PageSearch data={data} />
      ) : isBlogPage ? (
        <PageBlog data={data} />
      ) : isArticle ? (
        <Article data={data} />
      ) : isHoteriesPage ? (
        <PageHoteries data={data} />
      ) : isContactFormPage ? (
        <PageContactForm data={data} />
      ) : (
        <Page data={data} />
      )}
    </Layout>
  );
};

export default PageContainer;

export const PageFragment = graphql`
  fragment PageFragment on ContentfulPage {
    id
    node_locale
    contentful_id
    name
    slug
    city
    seoMetadata {
      ...SeoMetadataFragment
    }
    gatsbyPath(
      filePath: "/{ContentfulPage.node_locale}/{ContentfulPage.city}/{ContentfulPage.slug}"
    )
    sections {
      ...SectionFragment
    }
    faqs {
      ...FaqFragment
    }
    articles {
      ...ArticleFragment
    }
  }

  fragment SeoMetadataFragment on ContentfulSeoMetadata {
    id
    title
    description
    ogImage {
      ...AssetFragment
    }
  }

  fragment AssetFragment on ContentfulAsset {
    id
    title
    description
    gatsbyImageData(placeholder: NONE, width: 3500)
    file {
      url
    }
  }

  fragment ArticleFragment on ContentfulArticle {
    id
    contentful_id
    node_locale
    title
    slug
    city
    category
    isFeatured
    isNotArticle
    gatsbyPath(
      filePath: "/{ContentfulArticle.node_locale}/{ContentfulArticle.city}/{ContentfulArticle.category}/{ContentfulArticle.slug}"
    )
    richText {
      raw
      references {
        ... on ContentfulAsset {
          contentful_id
          title
          description
          gatsbyImageData(width: 1000)
          __typename
        }
      }
    }
    digest {
      childMarkdownRemark {
        html
        rawMarkdownBody
      }
    }
    slideshow {
      ...AssetFragment
    }
    publishedAt(formatString: "LL", locale: "en-US")
    sys {
      contentType {
        sys {
          id
        }
      }
    }
    buttons {
      ...ButtonFragment
    }
    faqs {
      ...FaqFragment
    }
  }

  fragment SectionFragment on ContentfulSection {
    id
    contentful_id
    node_locale
    title
    category
    digest {
      childMarkdownRemark {
        html
        rawMarkdownBody
      }
    }
    coverImage {
      ...AssetFragment
    }
    isFeatured
    buttons {
      ...ButtonFragment
    }
  }

  fragment FaqFragment on ContentfulFaq {
    id
    contentful_id
    node_locale
    title
    richText {
      raw
    }
  }

  fragment ButtonFragment on ContentfulButton {
    id
    contentful_id
    node_locale
    label
    internalLink {
      gatsbyPath(
        filePath: "/{ContentfulPage.node_locale}/{ContentfulPage.city}/{ContentfulPage.slug}"
      )
    }
    externalLink
  }
`;
