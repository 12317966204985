import React from 'react';

import { useSearchbar } from '../hooks/useSearchbar';

import Header from './Header';
import Sections from './Sections';
import ArticleCards from './ArticleCards';
import Searchbar from './Searchbar';

function PageHoteries({ data }) {


  const hoteries = data?.content?.articles;

  const { query, list } = useSearchbar(hoteries);

  return (
    <>
      <Header articles={hoteries}/>
      <div className="wrapper">
        <div className="blog two-column">
          <div className="two-column__container">
            <div className="two-column__side-panel">
              <Searchbar isSecondary isSmall />
              <ArticleCards articles={(query && list) ? list : hoteries} />
            </div>
            <div className="main">
              <Sections sections={hoteries} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PageHoteries;
