import React, { useContext, useRef } from 'react';

import { useDropdown } from '../hooks/useDropdown';
import { ViewportContext } from '../contexts/viewport';

import Button from './Button';

export default function Dropdown({ version, label, children }) {
  const { isSmallScreen } = useContext(ViewportContext);

  const dropdownRef = useRef(null);
  const { isActiveDropdown, setIsActiveDropdown } = useDropdown(dropdownRef);

  const style = `dropdown ${
    isActiveDropdown ? '--active' : ''
  }`;

  return (
    <>
      {children && (
        <div
          className={style}
          ref={dropdownRef}
          onMouseEnter={!isSmallScreen ? () => setIsActiveDropdown(true) : null}
          onMouseLeave={
            !isSmallScreen ? () => setIsActiveDropdown(false) : null
          }
        >
          <Button
            onClick={() => setIsActiveDropdown(!isActiveDropdown)}
            version={version}
            label={label}
          />
          {isActiveDropdown && <div className="dropdown__list">{children}</div>}
        </div>
      )}
    </>
  );
}
