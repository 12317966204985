/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';

import { FormattedMessage as Trans } from 'react-intl';

import { usePaths } from '../hooks/usePaths';
import { SEARCH_PAGE_CONTENTFUL_ID } from '../utils/variables';

import Button from './Button';
import ContentfulRichText from './ContentfulRichText';
import HeadingFour from './HeadingFour';
import Icon from './Icon';

function Faq({ faq }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const { title, richText } = faq;

  const style = `faq ${isExpanded ? '--active animate' : ''}`;

  const { getPagePathByContentfulId } = usePaths();

  const handleClick = () => setIsExpanded(!isExpanded);

  return (
    <>
      {faq && (
        <div className={style}>
          <div className="faq__title" onClick={handleClick}>
            <HeadingFour content={title} />
            <Button
              onClick={handleClick}
              version="wrapper"
            >
              <Icon
                icon={isExpanded ? 'MdKeyboardArrowUp' : 'MdKeyboardArrowDown'}
              />
            </Button>
          </div>
          {isExpanded && (
            <div className="faq__body">
              <ContentfulRichText richText={richText} />
              <Button
                internalLink={getPagePathByContentfulId(
                  SEARCH_PAGE_CONTENTFUL_ID
                )}
                version="dark"
                label={<Trans id="ctaFaqQuestion" />}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default Faq;
