import React from 'react';

import BackgroundImage from 'gatsby-background-image';
import { convertToBgImage } from 'gbimage-bridge';

import BlockContent from './BlockContent';
import ReadingTime from './ReadingTime';
import Slideshow from './Slideshow';

import { getFormattedString } from '../utils/getters';

export default function Header({ articles, isArticlePage }) {
  const slideshowElements = isArticlePage ? articles[0].slideshow : articles;
  return (
    <Slideshow isOneSlide={slideshowElements?.length === 1}>
      {slideshowElements?.map((element) => {
        const elementObject = isArticlePage
          ? {
              id: element?.id,
              bgImage: convertToBgImage(element?.gatsbyImageData),
              title: null,
              category: null,
              digest: null,
              richTextRaw: articles[0]?.richText.raw,
              gatsbyPath: articles[0]?.gatsbyPath,
              isDisabled: true,
              isBlockContent: false
            }
          : {
              id: element?.id,
              bgImage: convertToBgImage(element?.slideshow[0]?.gatsbyImageData),
              title: element?.title,
              category: element?.category,
              digest: element?.digest,
              richTextRaw: element?.richText?.raw,
              gatsbyPath: element?.gatsbyPath,
              isDisabled: false,
              isBlockContent: true
            };

        const style = `header${
          elementObject?.isBlockContent ? ' --background-overlay' : ''
        }`;

        return (
          <React.Fragment key={elementObject.id}>
            <BackgroundImage {...elementObject?.bgImage}>
              <header className={style}>
                {elementObject.isBlockContent && elementObject?.digest && (
                  <div className="wrapper">
                    <BlockContent
                      content={{
                        headingFour: getFormattedString(
                          elementObject?.category
                        ),
                        headingTwo: elementObject?.title,
                        digest:
                          elementObject?.digest?.childMarkdownRemark
                            ?.rawMarkdownBody,
                        buttons: [
                          {
                            label: (
                              <ReadingTime
                                richTextRaw={elementObject?.richTextRaw}
                              />
                            ),
                            version: 'text-light',
                            internalLink: elementObject?.gatsbyPath,
                            isDisabled: elementObject.isDisabled
                          }
                        ]
                      }}
                    />
                  </div>
                )}
              </header>
            </BackgroundImage>
          </React.Fragment>
        );
      })}
    </Slideshow>
  );
}
