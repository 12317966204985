import React from 'react';
import { FormattedMessage as Trans } from 'react-intl';

function FormFieldRadioButton({ name, formName, value, onChange, label, id }) {
  return (
    <>
      <div className="form-field-radio-button">
        <input
          id={id || name}
          name={formName}
          className="form-field-radio-button__input"
          type="radio"
          onChange={onChange}
          defaultChecked={value || false}
        />
        <label htmlFor={id || name} className="body --small">
          <Trans id={label}/>
        </label>
      </div>
    </>
  );
}

export default FormFieldRadioButton;
