import React from 'react';

import HeadingOne from './HeadingOne';
import HeadingTwo from './HeadingTwo';
import HeadingThree from './HeadingThree';
import HeadingFour from './HeadingFour';
import Body from './Body';
import Buttons from './Buttons';
import Digest from './Digest';

export default function BlockContent({ content }) {
  const {
    headingOne,
    headingTwo,
    headingThree,
    headingFour,
    body,
    buttons,
    digest,
    isHeadingTwoSmall,
  } = content || {};
  return (
    <>
      {content && (
        <div className="block-content">
          <HeadingFour content={headingFour} />
          <HeadingOne content={headingOne} />
          <HeadingTwo content={headingTwo} isHeadingTwoSmall={isHeadingTwoSmall}/>
          <Digest content={digest} />
          <HeadingThree content={headingThree} />
          <Body content={body?.childMarkdownRemark?.rawMarkdownBody || body} />
          <Buttons buttons={buttons}/>
        </div>
      )}
    </>
  );
}
