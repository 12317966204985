import { useDetectOutsideClick } from './useDetectOutsideClick';

export const useDropdown = (dropdownRef) => {
  const [isActiveDropdown, setIsActiveDropdown] = useDetectOutsideClick(
    dropdownRef,
    false
  );

  return {
    setIsActiveDropdown,
    isActiveDropdown
  };
};
