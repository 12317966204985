import React, { useContext } from 'react';

import { FormattedMessage as Trans } from 'react-intl';

import { ViewportContext } from '../contexts/viewport';
import { getFormattedString, getIllustrationSide, isMultipleOf } from '../utils/getters';

import Image from './Image';
import BlockContent from './BlockContent';


function Section({ section, index }) {
  const { isSmallScreen } = useContext(ViewportContext);

  const {
    title,
    category,
    digest,
    coverImage,
    gatsbyPath,
    buttons,
    slideshow,
  } = section;
  const style = `section --ilustration-${getIllustrationSide(index)}${
    isMultipleOf(index + 1, 4) & !isSmallScreen ? ' --featured' : ''
  }`;

  const articleCta = gatsbyPath
    ? {
        label: <Trans id="ctaLabelRead" />,
        internalLink: gatsbyPath,
        id: 'read-more-button'
      }
    : null;

  const getButtons = () => {
    var array = [];
    if (gatsbyPath) {
      array.push(articleCta);
    }

    if (buttons?.length > 0) {
      array.push(...buttons);
    }

    return array.slice(0,1);
  };

  return (
    <>
      {section && (
        <section className={style}>
          <Image file={coverImage || slideshow[0]} />
          <div className="section__content">
            <BlockContent
              content={{
                headingFour: getFormattedString(category),
                headingTwo: title,
                isHeadingTwoSmall: true,
                digest: digest?.childMarkdownRemark?.rawMarkdownBody,
                buttons: getButtons()
              }}
            />
          </div>
        </section>
      )}
    </>
  );
}

export default Section;
