/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';

import { useSearchbar } from '../hooks/useSearchbar';
import { removeNodes } from '../utils/getters';
import { scrollToTop } from '../utils/dom';

import HeaderSearch from './HeaderSearch';
import Sections from './Sections';
import Faqs from './Faqs';

function PageSearch({ data }) {
  const allArticles = data?.allArticles?.edges;
  const allFaqs = data?.allFaqs?.edges;

  const articles = removeNodes(allArticles).map((node) => {
    return {
      ...node,
      isFeatured: false
    };
  });

  const faqs = removeNodes(allFaqs);

  const { query, list } = useSearchbar([...faqs, ...articles]);

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <HeaderSearch />
      <div className="wrapper">
        <Faqs
          faqs={
            query && list
              ? list?.filter((item) => !item.category)
              : faqs.slice(0, 5)
          }
        />
        <Sections
          sections={
            query && list
              ? list?.filter((item) => item.category)
              : articles.slice(0, 5)
          }
        />
      </div>
    </>
  );
}

export default PageSearch;
