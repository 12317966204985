import React, { useState, useEffect, useRef } from 'react';

import { LanguageContext } from '../language';
import languages from '../../data/languages';

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('en');
  const [langKey, setLangKey] = useState('en');
  const [langs, setLangs] = useState(languages.langs);
  const [remainingLanguages, setRemainingLanguages] = useState([]);
  const [languageHasChanged, setLanguageHasChanged] = useState(false);
  const defaultLangKey = languages.defaultLangKey;
  const langMap = {
    en: 'English',
    'en-US': 'English (US)',
    'en-GB': 'English (GB)',
    fr: 'Français',
    it: 'Italiano'
  };

  const getLangKeyFromUrl = (pathname) =>
    pathname.includes('/en-us/')
      ? 'en-US'
      : pathname.includes('/en-gb/')
      ? 'en-GB'
      : pathname.includes('/it/')
      ? 'it'
      : pathname.includes('/fr/')
      ? 'fr'
      : 'en';

  const getLanguageFromLangKey = (langKey) => {
    return {
      'en-US': 'en',
      'en-GB': 'en',
      en: 'en',
      fr: 'fr',
      it: 'it'
    }[langKey];
  };

  const getRemainingLanguages = (languages, currentLangKey) =>
    languages.filter((l) => l !== currentLangKey);

  const getLangKeyFromBrowserSettings = (languages) => {
    const browserLanguage = navigator.language || navigator.userLanguage;
    return languages.langs.includes(browserLanguage) ? browserLanguage : 'en';
  };

  useEffect(() => {
    setLanguage(getLanguageFromLangKey(langKey));
    setRemainingLanguages(getRemainingLanguages(langs, langKey));
  }, [langKey]);

  return (
    <LanguageContext.Provider
      value={{
        language,
        setLangKey,
        langKey,
        langs,
        remainingLanguages,
        defaultLangKey,
        languageHasChanged,
        langMap,
        getLangKeyFromUrl,
        getLanguageFromLangKey,
        getRemainingLanguages,
        getLangKeyFromBrowserSettings
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};
