import React from 'react';

import Wrapper from './Wrapper';
import Button from './Button';
import Tooltip from './Tooltip';

export default function Buttons({ buttons }) {
  const isMoreThanOneButton = buttons?.length > 1;
  const isButtonWrapper = (button) => button?.version === 'wrapper';
  return (
    <>
      {buttons && (
        <Wrapper
          condition={isMoreThanOneButton}
          wrapper={(children) => <div className="btn__row">{children}</div>}
        >
          {buttons.map((button, index) => (
            <React.Fragment key={`${index}-${button.id}`}>
              {!isButtonWrapper(button) && (
                <Wrapper
                  condition={button?.tooltipLabel}
                  wrapper={(children) => (
                    <Tooltip label={button.tooltipLabel}>{children}</Tooltip>
                  )}
                >
                  <Button
                    isDisabled={button.isDisabled}
                    onClick={button.onClick}
                    label={button.label}
                    version={button.version}
                    internalLink={
                      button.internalLink?.gatsbyPath || button.internalLink
                    }
                    externalLink={button.externalLink}
                    type={button.type}
                  />
                </Wrapper>
              )}
            </React.Fragment>
          ))}
        </Wrapper>
      )}
    </>
  );
}
