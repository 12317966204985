import React from 'react';

import { usePaths } from '../hooks/usePaths';

import Button from './Button';

const Navlinks = () => {
  const { links } = usePaths();

  return (
    <ul>
      {links.slice(0,6).map((navlink) => (
        <li key={navlink.id}>
          <Button
            version="navbar-link"
            label={navlink.label}
            internalLink={navlink.internalLink || null}
            externalLink={navlink.externalLink || null}
          />
        </li>
      ))}
    </ul>
  );
};

export default Navlinks;
