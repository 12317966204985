import React, { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import 'intl';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';

import { useUseStaticQuery } from '../hooks/useUseStaticQuery';
import { useNavbar } from '../hooks/useNavbar';
import { useLanguage } from '../hooks/useLanguage';
import { usePaths } from '../hooks/usePaths';

import Navbar from './Navbar';
import Footer from './Footer';
import ButtonScrollToTop from './ButtonScrollToTop';

const Layout = ({ data, helmetMetadata, children }) => {
  const location = useLocation();

  // Get translated content
  const { translations } = useUseStaticQuery();

  // Get langKey for IntlProvider
  const { langKey } = useLanguage();

  // Manage burger state
  const { isBurgerMenu } = useNavbar();

  // Set page contentful id
  const { setPageContentfulId } = usePaths();

  useEffect(() => {
    setPageContentfulId(data?.content?.contentful_id);
  }, [data]);

  // Hide page when necessary
  const isPageHiddenFromBots = data?.content?.isPageHiddenFromBots || false;

  return (
    <IntlProvider
      locale={langKey}
      key={langKey}
      messages={translations}
      defaultLocale="fr"
    >
      <>
        <Helmet
          http-equiv="Content-Type"
          content="text/html; charset=UTF-8"
          key={location.href}
          title={helmetMetadata[3]?.content}
          defer={false}
          htmlAttributes={{
            lang: langKey,
            style: isBurgerMenu ? `overflow: hidden` : null
          }}
        >
          {isPageHiddenFromBots && (
            <meta name="robots" content="noindex, nofollow" />
          )}
          {helmetMetadata?.map(({ name, property, content }) => (
            <meta
              {...(property ? { property: property } : { name: name })}
              content={content}
            />
          ))}
          <script type="application/ld+json"></script>
          <meta
            name="facebook-domain-verification"
            content="8vdy66hmoslh5j5nij3u61oosanfqy"
          />
        </Helmet>
        <Navbar />
        {children}
        {!isBurgerMenu && <ButtonScrollToTop />}
        <Footer />
      </>
    </IntlProvider>
  );
};

export default Layout;
