import React from 'react';

import Image from './Image';
import HeadingFour from './HeadingFour';
import Button from './Button';

function ArticleCard({ article }) {
  const { slideshow, title, gatsbyPath } = article;

  return (
    <>
      {article && (
        <Button internalLink={gatsbyPath} version="wrapper">
          <div className="article-card">
            <Image file={slideshow[0]} />
            <HeadingFour content={title} />
          </div>
        </Button>
      )}
    </>
  );
}

export default ArticleCard;
