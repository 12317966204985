import React from 'react';
import Carousel from 'nuka-carousel';

export default function Slideshow({ children, isOneSlide }) {
  return (
    <>
      {children && (
        <Carousel
          autoplay={false}
          adaptiveHeightAnimation={true}
          wrapAround={true}
          slideCount={false}
          renderCenterLeftControls={false}
          renderCenterRightControls={false}
          defaultControlsConfig={{
            pagingDotsStyle: {
              fill: 'white'
            }
          }}
          dragging={!isOneSlide}
          dragThreshold={0.2}
          withoutControls={isOneSlide}
        >
          {children}
        </Carousel>
      )}
    </>
  );
}
