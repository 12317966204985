import React from 'react';
import Searchbar from './Searchbar';

export default function HeaderSearch() {

  return (
    <>
      <header className="header --search">
        <div className="wrapper">
          <Searchbar />
        </div>
      </header>
    </>
  );
}
