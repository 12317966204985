import React, { useContext } from 'react';
import { FormattedMessage as Trans } from 'react-intl';

import { SitePathsContext } from '../contexts/sitePaths';
import { useUseStaticQuery } from './useUseStaticQuery';
import { useLanguage } from './useLanguage';

import {
  SEARCH_PAGE_CONTENTFUL_ID,
  BLOG_PAGE_CONTENTFUL_ID,
  HOME_OWNER_PAGE_CONTENTFUL_ID,
  GUEST_PAGE_CONTENTFUL_ID,
  TERMS_PAGE_CONTENTFUL_ID,
  HOTERIES_PAGE_CONTENTFUL_ID,
  CONTACT_FORM_PAGE_CONTENTFUL_ID
} from '../utils/variables';

export const usePaths = () => {
  const { setPageContentfulId, pageContentfulId } =
    useContext(SitePathsContext);

  const { allPages, allArticles } = useUseStaticQuery();
  const allPaths = [...allPages.edges, ...allArticles.edges].map(({ node }) => {
    const path = {
      contentful_id: node.contentful_id,
      node_locale: node.node_locale,
      id: node.id,
      gatsbyPath: node.gatsbyPath
    };
    return path;
  });

  const { langKey } = useLanguage();

  const otherLanguagesPath = allPaths?.filter(
    (p) => p.node_locale !== langKey && p.contentful_id === pageContentfulId
  );

  const getPagePathByContentfulId = (c_id) =>
    allPaths?.filter(
      (p) => p.node_locale === langKey && p.contentful_id === c_id
    )[0]?.gatsbyPath;

  const links = [
    {
      label: <Trans id="hoteries" />,
      internalLink: getPagePathByContentfulId(HOTERIES_PAGE_CONTENTFUL_ID),
      id: 'homepage-link'
    },
    {
      label: <Trans id="homeOwners" />,
      internalLink: getPagePathByContentfulId(HOME_OWNER_PAGE_CONTENTFUL_ID),
      id: 'home-owners-link'
    },
    {
      label: <Trans id="guests" />,
      internalLink: getPagePathByContentfulId(GUEST_PAGE_CONTENTFUL_ID),
      id: 'guests-link'
    },
    {
      label: <Trans id="hotestories" />,
      internalLink: getPagePathByContentfulId(BLOG_PAGE_CONTENTFUL_ID),
      id: 'hotestories-link'
    },
    {
      label: <Trans id="ctaRent" />,
      internalLink: getPagePathByContentfulId(CONTACT_FORM_PAGE_CONTENTFUL_ID),
      id: 'contatc-form-link'
    },
    {
      label: <Trans id="ctaBook" />,
      externalLink: "https://www.airbnb.fr/users/show/544081308",
      id: 'book-link'
    },

    {
      label: <Trans id="terms" />,
      internalLink: getPagePathByContentfulId(TERMS_PAGE_CONTENTFUL_ID),
      id: 'terms-link'
    },
    {
      label: <Trans id="helpCenter" />,
      internalLink: getPagePathByContentfulId(SEARCH_PAGE_CONTENTFUL_ID),
      id: 'help-center-link'
    }
  ];

  return {
    setPageContentfulId,
    allPaths,
    getPagePathByContentfulId,
    otherLanguagesPath,
    links
  };
};
