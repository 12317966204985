import React from 'react';

export default function HeadingTwo({ content, isHeadingTwoSmall }) {
  return (
    <>
      {content && (
        <h2 className={`heading-2 ${isHeadingTwoSmall ? '--small' : ''}`}>
          {content}
        </h2>
      )}
    </>
  );
}
