import React from 'react';

import { useSearchbar } from '../hooks/useSearchbar';
import { removeNodes } from '../utils/getters';

import Header from './Header';
import Sections from './Sections';
import ArticleCards from './ArticleCards';
import Searchbar from './Searchbar';

function PageBlog({ data }) {

  const allArticles = data?.allArticles?.edges;
  const newestArticle = removeNodes(allArticles.slice(0, 1));
  const newestArticles = removeNodes(allArticles.slice(1, 5));
  const otherArticles = removeNodes(allArticles.slice(1, -1));

  const { query, list } = useSearchbar(removeNodes(allArticles));

  return (
    <>
      <Header articles={newestArticle}/>
      <div className="wrapper">
        <div className="blog two-column">
          <div className="two-column__container">
            <div className="two-column__side-panel">
              <Searchbar isSecondary isSmall />
              <ArticleCards articles={(query && list) ? list : newestArticles} />
            </div>
            <div className="main">
              <Sections sections={otherArticles} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PageBlog;
