/* eslint-disable no-nested-ternary */
import React from 'react';

import Faq from './Faq';
import HeadingThree from './HeadingThree';

function Faqs({ faqs, title }) {
  return (
    <>
      {faqs && (
        <div className="faqs">
          <>
            <HeadingThree content={title?.toUpperCase()} />
            {faqs.map((faq) => (
              <React.Fragment key={faq?.id}>
                <Faq faq={faq} />
              </React.Fragment>
            ))}
          </>
        </div>
      )}
    </>
  );
}

export default Faqs;
