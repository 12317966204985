import React from 'react';
import { FormattedMessage as Trans } from 'react-intl';

import { usePaths } from '../hooks/usePaths';

import Button from './Button';
import SocialNetworks from './SocialNetworks';
import HoterieLogo from './HoterieLogo';
import DropdownLanguages from './DropdownLanguages';

function Footer() {
  const { links } = usePaths();

  const footerSections = [
    {
      id: 'footer-offices-section',
      title: <Trans id="offices" />,
      links: [
        {
          isLabelHtml: true,
          label:
            '<div>France<hr>HOTERIE Paris <hr> 13 rue Boinod, 75018, Paris, France <hr> +33 6 22 89 96 76</></div>',
          externalLink:
            'https://www.google.com/maps/place/13+Rue+Boinod,+75018+Paris/@48.8928873,2.3477631,17z/data=!3m1!4b1!4m6!3m5!1s0x47e66e60e1e28fad:0x6e79c637908ee9d9!8m2!3d48.8928838!4d2.350338!16s%2Fg%2F11bw42rwd2?entry=ttu',
          id: 'footer-paris-office'
        },
        {
          isLabelHtml: true,
          label:
            '<div>Italia<hr>HOTERIE Roma <hr> 86 vicolo del Bologna, 8600153, Roma, Italia <hr> +33 6 22 89 96 76 </></div>',
          externalLink:
            'https://www.google.com/maps/place/Vicolo+del+Bologna,+86,+00153+Roma+RM,+Italie/@41.890956,12.4641024,17z/data=!3m1!4b1!4m6!3m5!1s0x132f6047723f0dd3:0xa23c42ab05bf00cd!8m2!3d41.8909521!4d12.4689733!16s%2Fg%2F11c5kv1ngk?entry=ttu',
          id: 'footer-roma-office'
        },
        {
          isLabelHtml: false,
          label: 'staff@hoterie.com',
          externalLink: "mailto:staff@convelio.com?subject=Louez vos biens avec Hoterie",
          id: 'footer-email-office'
        }
      ]
    },
    {
      id: 'footer-hoterie-section',
      title: 'Hoterie',
      links: links.slice(0, 4)
    },
    {
      id: 'footer-legal-section',
      title: <Trans id="utils" />,
      links: links.slice(4)
    }
  ];

  const FooterInfo = ({ title, links }) => (
    <div className="footer__section">
      {title && <p className="heading-4">{title}</p>}
      {links?.map((button) => {
        return (
          <React.Fragment key={button.id}>
            <Button
              isLabelHtml={button.isLabelHtml}
              label={button.label}
              formattedLabel={button.formattedLabel}
              externalLink={button.externalLink}
              id={button.id}
              version="footer-link"
              internalLink={button.internalLink}
            />
          </React.Fragment>
        );
      })}
    </div>
  );
  return (
    <>
      <footer
        className="footer --dark"
        id="footer"
        role="contentinfo"
        itemScope
        itemType="http://schema.org/WPFooter"
      >
        <div className="wrapper">
          <HoterieLogo color="white" />
          <div className="footer__container">
            {footerSections?.map(({ title, links, id }) => (
              <FooterInfo key={id} title={title} links={links} />
            ))}
            <div>
              {/* <SocialNetworks /> */}
              <DropdownLanguages />
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
